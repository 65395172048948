import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"

const CollaborativePage = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
    <section class="hero is-halfheight hero-banner" id="initiative-hero">
          <div class="hero-body">
          </div>
    </section>            
      <main>
        <article itemScope itemType="http://schema.org/Article">
          <header>
            <h1 itemProp="headline">
              {post.frontmatter.title}
            </h1>
          </header>

          <section
            dangerouslySetInnerHTML={{ __html: post.html }}
            itemProp="articleBody"
          />
        </article>
      </main>
    </Layout>
  )
}

export default CollaborativePage

export const pageQuery = graphql`
  query CollaborativePageBySlug($slug: String!) {
    site {
      siteMetadata {
        title,
        siteUrl
      }
    }
    markdownRemark(fields: { slug: { eq: $slug } }) {
      id
      fields { slug }
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        topics
      }
    }
  }
`